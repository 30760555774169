import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/(standard)": [5,[3]],
		"/(standard)/admin/betrokkenen": [6,[3]],
		"/(standard)/admin/eigenaar": [7,[3]],
		"/(standard)/admin/gebruikers": [8,[3]],
		"/(standard)/admin/installatie": [9,[3]],
		"/(standard)/admin/luchtfoto": [10,[3]],
		"/(standard)/beleid": [11,[3]],
		"/(standard)/contact": [12,[3]],
		"/(standard)/digitoegankelijkheid": [13,[3]],
		"/(standard)/exports": [14,[3]],
		"/(standard)/hulp": [15,[3]],
		"/(map)/kaart": [4,[2]],
		"/(standard)/login": [16,[3]],
		"/(standard)/registreren": [17,[3]],
		"/(standard)/testing": [18,[3]],
		"/(standard)/uploads/error": [19,[3]],
		"/(standard)/uploads/luchtfoto": [20,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';