import * as Sentry from '@sentry/sveltekit';

import { CONFIG } from './config';

if (CONFIG.env != 'dev') {
	Sentry.init({
		dsn: 'https://9af52deda09547468377a4ba228cda01@sentry.sobolt.com/5',
		integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			'localhost',
			'https://test.zonnedakje.nl',
			'https://zonnedakje.nl'
		],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		// profilesSampleRate: 0.5,

		release: CONFIG.version,
		environment: CONFIG.env
	});

	Sentry.setTag('svelteKit', 'browser');
}

// This will catch errors in load functions from +page.ts files
const myErrorHandler = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
